export const state = () => ({
    isLoginModalOpen: false,
    isSearchModalOpen: false,
    breadcrumbData: [],
});

export const mutations = {
    setIsLoginModalOpen(state, payload) {
        state.isLoginModalOpen = payload;
    },
    setIsSearchModalOpen(state, payload) {
        state.isSearchModalOpen = payload;
    },
    setBreadcrumbData(state, payload) {
        state.breadcrumbData = payload;
    },
};
