import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e7a7b05 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _377a4970 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _35851813 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _1cd6ac07 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _d0881e58 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b5cda62 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _3e5e91ed = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _927f349e = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _6dbc743c = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _4ad42398 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _695c2a2d = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _4a86f5aa = () => interopDefault(import('../pages/implant-oral-surgery.vue' /* webpackChunkName: "pages/implant-oral-surgery" */))
const _363b4f39 = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _18b5889a = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _42126f5a = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _ec58babc = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _0b810316 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _44a7e3d3 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _09e90b84 = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _10b53a62 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _1c08ecd0 = () => interopDefault(import('../pages/premium-dental-care.vue' /* webpackChunkName: "pages/premium-dental-care" */))
const _843e7638 = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _08fa2d9a = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _af5e4d78 = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _71d06e34 = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _525d346c = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _19ac2131 = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _364a20c8 = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _27c878e3 = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _2a2dbf38 = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _46bdb05f = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _868342f6 = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _7b3c5aef = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _3344bb63 = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _27dab3e3 = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _54bbe6fa = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _8f669514 = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _b565b62c = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _26353086 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _6a4bda4c = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _44585380 = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _5e7a7b05,
    name: "privacy-policy___hu"
  }, {
    path: "/araink",
    component: _377a4970,
    name: "pricing___hu"
  }, {
    path: "/blog",
    component: _35851813,
    name: "blog___hu"
  }, {
    path: "/elfelejtett-jelszo",
    component: _1cd6ac07,
    name: "forgot-password___hu"
  }, {
    path: "/en",
    component: _d0881e58,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _4b5cda62,
    name: "terms-and-conditions___hu"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _3e5e91ed,
    name: "payment-methods___hu"
  }, {
    path: "/fogaszati-panaszok",
    component: _927f349e,
    name: "dental-problems___hu"
  }, {
    path: "/garancia",
    component: _6dbc743c,
    name: "quality-guarantee___hu"
  }, {
    path: "/gyakran-ismetelt-kerdesek",
    component: _4ad42398,
    name: "faq___hu"
  }, {
    path: "/idopontfoglalas",
    component: _695c2a2d,
    name: "appointment-booking___hu"
  }, {
    path: "/implantatum-szajsebeszet",
    component: _4a86f5aa,
    name: "implant-oral-surgery___hu"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _363b4f39,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/jelszovaltoztatas",
    component: _18b5889a,
    name: "change-password___hu"
  }, {
    path: "/jogi-nyilatkozat",
    component: _42126f5a,
    name: "legal-notice___hu"
  }, {
    path: "/kapcsolat",
    component: _ec58babc,
    name: "contact___hu"
  }, {
    path: "/kereses",
    component: _0b810316,
    name: "search___hu"
  }, {
    path: "/leiratkozas",
    component: _44a7e3d3,
    name: "unsubscribe___hu"
  }, {
    path: "/munkaink",
    component: _09e90b84,
    name: "works___hu"
  }, {
    path: "/szolgaltatasok",
    component: _10b53a62,
    name: "services___hu"
  }, {
    path: "/en/appointment-booking",
    component: _695c2a2d,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _35851813,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _18b5889a,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _ec58babc,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _927f349e,
    name: "dental-problems___en"
  }, {
    path: "/en/faq",
    component: _4ad42398,
    name: "faq___en"
  }, {
    path: "/en/forgot-password",
    component: _1cd6ac07,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _363b4f39,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _42126f5a,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _3e5e91ed,
    name: "payment-methods___en"
  }, {
    path: "/en/premium-dental-care",
    component: _1c08ecd0,
    name: "premium-dental-care___en"
  }, {
    path: "/en/pricing",
    component: _377a4970,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _5e7a7b05,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _6dbc743c,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _0b810316,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _10b53a62,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _4b5cda62,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe",
    component: _44a7e3d3,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _09e90b84,
    name: "works___en"
  }, {
    path: "/profilom/ajalnasok",
    component: _843e7638,
    name: "account-referral-program___hu"
  }, {
    path: "/profilom/beallitasok",
    component: _08fa2d9a,
    name: "account-settings___hu"
  }, {
    path: "/profilom/idopontjaim",
    component: _af5e4d78,
    name: "account-appointments___hu"
  }, {
    path: "/profilom/vezerlopult",
    component: _71d06e34,
    name: "account-dashboard___hu"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _525d346c,
    name: "about-introduction___hu"
  }, {
    path: "/rolunk/karrier",
    component: _19ac2131,
    name: "about-career___hu"
  }, {
    path: "/rolunk/kepzeseink",
    component: _364a20c8,
    name: "about-trainings___hu"
  }, {
    path: "/rolunk/orvosaink",
    component: _27c878e3,
    name: "about-dentists___hu"
  }, {
    path: "/rolunk/velemenyek",
    component: _2a2dbf38,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career",
    component: _19ac2131,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _27c878e3,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _525d346c,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _2a2dbf38,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _364a20c8,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _af5e4d78,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _71d06e34,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _843e7638,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _08fa2d9a,
    name: "account-settings___en"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _46bdb05f,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _868342f6,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/en/about/career/:job",
    component: _7b3c5aef,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _3344bb63,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _27dab3e3,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _54bbe6fa,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _8f669514,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _46bdb05f,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _868342f6,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/blog/preview/:article_preview",
    component: _8f669514,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/en/about/:content",
    component: _b565b62c,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _26353086,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _6a4bda4c,
    name: "services-service___en"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _27dab3e3,
    name: "about-notifications-notification___hu"
  }, {
    path: "/rolunk/karrier/:job",
    component: _7b3c5aef,
    name: "about-career-job___hu"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _54bbe6fa,
    name: "about-trainings-training___hu"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _3344bb63,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/en/services/:service/:price",
    component: _44585380,
    name: "services-service-price___en"
  }, {
    path: "/blog/:article",
    component: _26353086,
    name: "blog-article___hu"
  }, {
    path: "/rolunk/:content",
    component: _b565b62c,
    name: "about-content___hu"
  }, {
    path: "/szolgaltatasok/:service",
    component: _6a4bda4c,
    name: "services-service___hu"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _44585380,
    name: "services-service-price___hu"
  }, {
    path: "/",
    component: _d0881e58,
    name: "index___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
